import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const HairAndScalpProducts = () => (
  <Layout>
    <SEO
      title="Use Different Products For Your Hair and Scalp"
      desciption="For those of us that are not so lucky, having a separate care routine for your scalp and hair can make a big difference"
    />
    <h1>Use Different Products For Your Hair and Scalp</h1>

    <p>Your hair and your scalp are two very different things. It might seem like a good idea to treat them the same, since your hair does come out of your scalp. You might also be one of the many people that can get away with it for a long time without seeing any negative effects. For those of us that are not so lucky, having a separate care routine for your scalp and hair can make a big difference.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/RsiWwfy.jpg"
        alt="Use different products for your hair and scalp"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@fideletty"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Etty Fidele</a></p>
    </div>

    <p>Your scalp holds your follicles and its health comes mainly from the nutrients in your diet, hydration and physical care. The care in terms of products is only a small part of your scalp health. If you have a dry and flaky scalp, you probably have a problem in your diet or you are dehydrated but most of us don’t want to hear that and would rather buy a product as a remedy instead.</p>
    <p>When you are looking for a product for scalp care, try to get something specifically made for it. These products are usually lighter than products made for the hair and let your scalp breathe. It might seem like as much moisture as possible is a good thing but a build-up and flakes can actually be caused by heavy products sitting on the scalp.</p>

    <h2>Scalp Care</h2>
    <p>Massaging the scalp during washing and applying moisture is great because it promotes blood circulation which helps your hair follicles stay healthy. Therefore, promoting hair growth. Gently exfoliating the scalp with a light shampoo helps remove excess dead skin. It’s natural for old skin to come away but the problem is when it happens quickly and excessively. Also, the hair holds it in place so without assistance removing it, it will become visible.</p>
    <p>You might not need to wash your hair and scalp as much as you think. Washing the hair strips away the natural oils which help maintain the balance of what is happening on your scalp. Not enough of these natural oils means they aren’t around long enough to do their job. If you are struggling with scalp health, try to change the time between washes and make a note of any benefits you see.</p>

    <h2>Hair Care</h2>
    <p>Just like the scalp, hair benefits from a good diet, meaning you are getting all of your essential vitamins and minerals. Try products that don’t contain sulphates and alcohols although this is a personal choice and a lot of people respond just fine to these ingredients. You might respond well to washing your hair less frequently because, again, you might be stripping away natural oils that protect your hair. Hair responds well to protein treatments so think about adding protein rich ingredients when you are doing a deep condition.</p>

    <h2>Conclusion</h2>
    <p>Most companies are really great at labelling their products for use with the hair and scalp. Generally, hair care products are safe to use for both hair and scalp but when they get too heavy, it’s recommended that you find something else for the scalp. If you have a line of products that you love to use already, browse the product website and see if they have a section to filter for hair and scalp products.</p>

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default HairAndScalpProducts
